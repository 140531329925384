<template>
  <div>
    <div class="app-content">
      <div class="header">
        <Header
          v-if="$route.path !== '/login'"
          v-show="$route.query.isMobile != 'true'"
        />
      </div>
      <div class="content">
        <!-- <OrderLists v-if="brandID" /> -->
        <!-- <router-view v-if="brandID" /> -->
        <router-view />
      </div>
      <b-modal
        v-model="isShowModalOverLay"
        no-fade
        hide-footer
        hide-header
        centered
        size="sm"
      >
        <div class="text-center">
          <img
            src="@/assets/images/loading.svg"
            alt="loading"
            class="my-2 loading-icon"
          />
          <div class="textc-primary">กรุณารอสักครู่</div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Layouts/Header.vue";

import { mapGetters } from "vuex";
export default {
  components: {
    Header
  },
  data() {
    return {
      brandId: null,
      config: {},
      forceRefresh: true,
      brandLists: [],
      isOrder: false,
      pathUrl: this.$baseUrl
    };
  },
  created() {
    this.isOrder = this.$route.meta.isOrder;
    if (this.isOrder == true) {
      this.pathUrl = this.$baseUrlOrder;
    }
    this.brandId = new URL(location.href).searchParams.get("brandID");
    this.getAllBrand(this.brandId);
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      brandID: "getBrand",
      isStore: "getStoreFlag",
      isShowModalOverLay: "getIsLoading"
    })
  },
  mounted() {},
  methods: {
    async getAllBrand(brandId) {
      var path = "/api/brand/list";
      // if (this.isOrder) {
      path = "/api/brand";
      // }
      await this.$axios.get(this.pathUrl + path).then(response => {
        if (response.data.result === 1) {
          // temp
          response.data.detail = response.data.detail.filter(
            item => item.id == 99
          );
          // end end
          this.brandLists = response.data.detail;
          if (response.data.detail.length !== 0) {
            this.$store.dispatch("setBrandLists", response.data.detail);
            this.$store.dispatch("setUserPermission", true);
            if (!brandId) {
              brandId = response.data.detail[0].id;
              this.$store.dispatch("setBrand", response.data.detail[0].id);
              this.getBrandConfig(brandId);
              this.getPermission(brandId);
              this.$router
                .replace({
                  path: "/",
                  query: { brandID: brandId }
                })
                .catch(err => {
                  console.log("err", err);
                });
            } else if (brandId) {
              if (!this.textValidate(brandId)) {
                this.$store.dispatch("setBrand", response.data.detail[0].id);
                brandId = response.data.detail[0].id;
                this.$router
                  .replace({
                    path: "/",
                    query: { brandID: brandId }
                  })
                  .catch(err => {
                    console.log("err", err);
                  });
              } else {
                var checkBrandFlag = this.brandLists.some(
                  el => el.id === Number(brandId)
                );
                if (!checkBrandFlag) {
                  brandId = response.data.detail[0].id;
                  this.$store.dispatch("setBrand", response.data.detail[0].id);
                  this.$router
                    .replace({
                      path: "/",
                      query: { brandID: brandId }
                    })
                    .catch(err => {
                      console.log("err", err);
                    });
                } else {
                  this.$store.dispatch("setBrand", this.brandId);
                }

                // this.$emit("OrderLists");
              }
              this.getBrandConfig(brandId);
              this.getPermission(brandId);
            }
            //this.$emit("OrderLists");
          } else {
            if (this.$route.path !== "/AccessDenied") {
              this.$router.push({
                path: "/AccessDenied"
              });
            }
          }
        }
      });
    },
    handleForcefresh() {
      this.forceRefresh = false;
      this.$nextTick(() => {
        this.forceRefresh = true;
      });
    },
    textValidate(text) {
      var textReg = /^\d+$/;
      return textReg.test(text);
    },
    getPermission(brandId) {
      this.$axios
        .post(this.pathUrl + "/api/User/Brand/" + brandId + "/Permission")
        .then(response => {
          if (response.data.result === 1) {
            this.$store.dispatch("setPermission", response.data.detail.finance);
          } else {
            alert(response.data.message);
          }
        });
    },
    getBrandConfig(brandId) {
      this.$axios
        .get(this.pathUrl + "/api/brand/" + brandId + "/config")
        .then(response => {
          this.config = response.data.detail;
          //this.$store.dispatch("setPermission", response.data.detail.finance);
          this.$store.dispatch("setBrandLogo", response.data.detail.logo);
          this.$store.dispatch(
            "setTheOneCardFlag",
            response.data.detail.useThe1Card
          );
          this.$store.dispatch("setBrandName", response.data.detail.name);
          this.$store.dispatch(
            "setStoreFlag",
            response.data.detail.isStoreFulfillment
          );
          this.$store.dispatch(
            "setBrandColor",
            response.data.detail.colorThemeMain
          );
        });
    }
  }
};
</script>

<style scoped></style>
